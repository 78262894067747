html, body {
  height: 100%;
  font-family: Helvetica;
}

#root, .App {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.AppLogo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.AppHeader {
  height: 150px;
  padding: 20px;
  color: white;
}

.AppTitle {
  font-size: 1.5em;
}

.AppIntro {
  font-size: large;
}

@keyframes AppLogoSpin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.bubble {
  position: relative;
  width: 305px;
  height: 120px;
  padding: 0px;
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: #dfa2da solid 4px;
}

.bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 20px 15px 0;
  border-color: white transparent;
  display: block;
  width: 0;
  z-index: 1;
  margin-left: -15px;
  bottom: -20px;
  left: 90%;
}

.bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 23px 18px 0;
  border-color: #dfa2da transparent;
  display: block;
  width: 0;
  z-index: 0;
  margin-left: -18px;
  bottom: -27px;
  left: 90%;
}

.center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#HomePage, #VideosPage, #HowToStopAWeddingPage, #TrashPage {
  background-color: black;
}
#HomePage a, #VideosPage a, #HowToStopAWeddingPage a, #TrashPage a {
  color: white;
  text-decoration: none;
}

#HomePage, #TrashPage {
  padding: 20px;
}

#HomePage div, #VideosPage div {
  padding: 20px;
}
#HomePage div a, #VideosPage div a {
  display: block;
  margin-bottom: 10px;
}

#ShowsPage {
  padding: 10px;
  font-size: 20px;
}
#ShowsPage .show {
  margin-bottom: 20px;
}
#ShowsPage .show a {
  color: #2196f3;
  text-decoration: none;
}
#ShowsPage .show a:hover {
  color: #0d47a1;
}
#ShowsPage .show .url {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}
#ShowsPage .show iframe {
  margin: 0 5px 5px 0;
}

#HowToStopAWeddingPage .center {
  height: 100%;
}
#HowToStopAWeddingPage .container {
  position: relative;
  height: 503px;
  width: 670px;
  overflow: hidden;
  cursor: pointer;
}
#HowToStopAWeddingPage .container .step {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  left: 0px;
  font-size: 500px;
  text-align: center;
  line-height: 503px;
  font-family: "Helvetica Neue";
  color: #e8174d;
  font-weight: bold;
  height: 503px;
  width: 670px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
}
#HowToStopAWeddingPage .container .step:hover {
  color: white;
  -webkit-animation: neon 0.5s ease-in-out infinite alternate;
  -moz-animation: neon 0.5s ease-in-out infinite alternate;
  animation: neon 0.5s ease-in-out infinite alternate;
}
#HowToStopAWeddingPage .container img {
  position: absolute;
  left: 0px;
  z-index: 0;
}

@-webkit-keyframes neon {
  from {
    text-shadow: 0 0 25px white, 0 0 50px white, 0 0 75px white, 0 0 100px #ff1177, 0 0 125px #ff1177, 0 0 150px #ff1177, 0 0 175px #ff1177, 0 0 200px #ff1177;
  }

  to {
    text-shadow: 0 0 12px white, 0 0 25px white, 0 0 37px white, 0 0 50px #ff1177, 0 0 67px #ff1177, 0 0 75px #ff1177, 0 0 87px #ff1177, 0 0 100px #ff1177;
  }
}
@-moz-keyframes neon {
  from {
    text-shadow: 0 0 25px white, 0 0 50px white, 0 0 75px white, 0 0 100px #ff1177, 0 0 125px #ff1177, 0 0 150px #ff1177, 0 0 175px #ff1177, 0 0 200px #ff1177;
  }

  to {
    text-shadow: 0 0 12px white, 0 0 25px white, 0 0 37px white, 0 0 50px #ff1177, 0 0 67px #ff1177, 0 0 75px #ff1177, 0 0 87px #ff1177, 0 0 100px #ff1177;
  }
}
@-ms-keyframes neon {
  from {
    text-shadow: 0 0 25px white, 0 0 50px white, 0 0 75px white, 0 0 100px #ff1177, 0 0 125px #ff1177, 0 0 150px #ff1177, 0 0 175px #ff1177, 0 0 200px #ff1177;
  }

  to {
    text-shadow: 0 0 12px white, 0 0 25px white, 0 0 37px white, 0 0 50px #ff1177, 0 0 67px #ff1177, 0 0 75px #ff1177, 0 0 87px #ff1177, 0 0 100px #ff1177;
  }
}
@keyframes neon {
  from {
    text-shadow: 0 0 25px white, 0 0 50px white, 0 0 75px white, 0 0 100px #ff1177, 0 0 125px #ff1177, 0 0 150px #ff1177, 0 0 175px #ff1177, 0 0 200px #ff1177;
  }

  to {
    text-shadow: 0 0 12px white, 0 0 25px white, 0 0 37px white, 0 0 50px #ff1177, 0 0 67px #ff1177, 0 0 75px #ff1177, 0 0 87px #ff1177, 0 0 100px #ff1177;
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#VeneryPage {
  background-color: black;
}
#VeneryPage * {
  padding: 0;
  margin: 0;
}
#VeneryPage #animals a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 4px;
}
#VeneryPage #animal {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#VeneryPage #animal .background {
  position: absolute;
  left: -200%;
  top: -200%;
  width: 400%;
  height: 400%;
  z-index: -1;
  -ms-transform-origin: 60% 60%;
  -webkit-transform-origin: 60% 60%;
  transform-origin: 60% 60%;
}
#VeneryPage #animal .text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 0px;
  color: white;
  text-shadow: 5px 5px black;
  font-weight: bold;
  word-wrap: break-word;
  cursor: pointer;
}

#FoodPage .container {
  position: relative;
}
#FoodPage .container .name {
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
  position: fixed;
  top: 200px;
  left: 150px;
  font-weight: bold;
  font-size: 0;
}
#FoodPage .audio {
  display: none;
}

@-webkit-keyframes intervention {
  from {
    text-shadow: 2px 2px 0px yellow, 1px -5px 1px magenta, -2px -2px 2px cyan, 3px -3px 3px red, -4px 4px 2px lime, -5px -5px 3px blue, 6px 6px 2px white, -8px 8px 3px black;
    font-size: 100px;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    transform: rotate(-8deg);
    color: deepskyblue;
  }

  to {
    text-shadow: -2px 2px 0px magenta, 1px -1px 1px cyan, 2px -2px 2px lime, -4px -4px 3px blue, -5px 5px 2px red, 4px 4px 1px cyan, -6px 6px 2px magenta, -10px 10px 2px yellow;
    font-size: 600px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
    color: #499df5;
  }
}
@-moz-keyframes intervention {
  from {
    text-shadow: 2px 2px 0px yellow, 1px -5px 1px magenta, -2px -2px 2px cyan, 3px -3px 3px red, -4px 4px 2px lime, -5px -5px 3px blue, 6px 6px 2px white, -8px 8px 3px black;
    font-size: 100px;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    transform: rotate(-8deg);
    color: deepskyblue;
  }

  to {
    text-shadow: -2px 2px 0px magenta, 1px -1px 1px cyan, 2px -2px 2px lime, -4px -4px 3px blue, -5px 5px 2px red, 4px 4px 1px cyan, -6px 6px 2px magenta, -10px 10px 2px yellow;
    font-size: 600px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
    color: #499df5;
  }
}
@-ms-keyframes intervention {
  from {
    text-shadow: 2px 2px 0px yellow, 1px -5px 1px magenta, -2px -2px 2px cyan, 3px -3px 3px red, -4px 4px 2px lime, -5px -5px 3px blue, 6px 6px 2px white, -8px 8px 3px black;
    font-size: 100px;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    transform: rotate(-8deg);
    color: deepskyblue;
  }

  to {
    text-shadow: -2px 2px 0px magenta, 1px -1px 1px cyan, 2px -2px 2px lime, -4px -4px 3px blue, -5px 5px 2px red, 4px 4px 1px cyan, -6px 6px 2px magenta, -10px 10px 2px yellow;
    font-size: 600px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
    color: #499df5;
  }
}
@keyframes intervention {
  from {
    text-shadow: 2px 2px 0px yellow, 1px -5px 1px magenta, -2px -2px 2px cyan, 3px -3px 3px red, -4px 4px 2px lime, -5px -5px 3px blue, 6px 6px 2px white, -8px 8px 3px black;
    font-size: 100px;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    transform: rotate(-8deg);
    color: deepskyblue;
  }

  to {
    text-shadow: -2px 2px 0px magenta, 1px -1px 1px cyan, 2px -2px 2px lime, -4px -4px 3px blue, -5px 5px 2px red, 4px 4px 1px cyan, -6px 6px 2px magenta, -10px 10px 2px yellow;
    font-size: 600px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
    color: #499df5;
  }
}
#intervention {
  width: 100%;
  height: 100%;
  position: relative;
}
#intervention iframe {
  width: 100%;
  height: 100%;
}
#intervention iframe#loader {
  opacity: 0;
}
#intervention #text {
  position: absolute;
  top: -100px;
  left: -100px;
  font-size: 150px;
  font-weight: bold;
  font-family: Helvetica;
  -webkit-animation: intervention 10s ease-in-out infinite alternate;
  -moz-animation: intervention 10s ease-in-out infinite alternate;
  animation: intervention 10s ease-in-out infinite alternate;
}
