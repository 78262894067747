.SquarePage {
  .OperatorGrid {
    display: inline-block;
    padding: 10px;
  }

  .SquareCanvasTiled {
    margin: auto;
  }
}
