@import "../Core/mixins.scss";

@keyframes FadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes FadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.Nav {
	position: absolute;
  z-index: 100;
	top: 0;
	left: 0;
  bottom: 0;

	.NavTriangle {
		opacity: 0;
		cursor: pointer;
		width: 0;
		height: 0;
		border-left: 75px solid transparent;
		border-right: 75px solid transparent;
		border-top: 75px solid $blue;
		transform: translate(-50px, -13px) rotate(135deg);
		-webkit-animation: FadeOut 2s;
						animation: FadeOut 2s;
	}

	.NavTriangle:hover {
		opacity: 1;
		-webkit-animation: FadeIn 0.25s;
						animation: FadeIn 0.25s;
	}

	.NavContent {
		background-color: $blue;
		padding: 0 20px 0 20px;
		min-height: 100%;
    font-family: Helvetica;

		.Link {
      color: white;
			display: block;
      text-decoration: none;
      font-weight: bold;
      font-size: 36px;
      margin-bottom: 5px;
      &:hover {
        color: black;
      }
		}

		.NavClose {
			cursor: pointer;
			text-decoration: none;
			font-size: 30px;
			color: black;
      margin-bottom: 20px;

      &:hover {
        color: white;
      }
		}
	}
}
