.TelegenicsPage {
	text-align: center;
	min-height: 100%;
	background-color: black;

	.telegenics_container {
		padding-top: 20px;
		padding-bottom: 100px;
		width: 1008px;
		margin-left: auto;
		margin-right: auto;

		.history {
			color: #CCFFFF;
			font-size: 11.0pt;
			font-style: italic;
			font-family: "Times New Roman", serif;
		}

		.scroll {
			color: yellow;
			font-family: "Arial Black", sans-serif;
			font-size: 10.0pt;
		  font-weight: 400;
		  font-style: normal;
		  text-decoration: none;
			margin-bottom: 10px;
		}

		.loading {
			color: red;
	    font-size: 15.0pt;
	    font-weight: 700;
	    font-family: "Arial Narrow", sans-serif;
			margin-bottom: 40px;
		}

		.reel {
			margin-bottom: 20px;

			.reel_spaced {
				color: lime;
				font-size: 22.0pt;
				font-weight: 700;
				font-style: italic;
				font-family: Impact, sans-serif;
				mso-font-charset: 0;
				text-decoration: underline;

				.yesspacerun {
					mso-spacerun: yes;
				}
			}

			.reel_count {
				font-family: Impact, sans-serif;
				color: white;
				font-size: 10.0pt;
				font-weight: 400;
				font-style: normal;
				text-decoration: none;
			}
		}

		.no_payoff {
			margin-top: 20000px;

			.alphanums {
				margin-bottom: 40px;
				text-align: left;
				
				a {
					display: inline-block;
					color: #3366FF;
					font-size: 9.5pt;
					font-family: "Arial Black", sans-serif;
					text-decoration: none;
				}
			}

			.totals {
				border: .5pt solid white;
			  font-size: 15.0pt;
			  font-weight: 400;
			  font-style: italic;
			  text-decoration: none;
			  font-family: "Arial Black", sans-serif;
				color: fuchsia;

				.font548 {
					color: lime;
				}
				.font17 {
					color: yellow;
				}
				.font46 {
					color: lime;
				}
			}
		}
	}
}
