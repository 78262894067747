.Logo {
  position: relative;
  cursor: pointer;
  height: 82px;
  width: 104px;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  .Front {
    position: absolute;
    z-index: 1001;
    background-color: #dddddd;
  }

  .Back {
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
  }

  .LogoS div {
    height: 13px;
    width: 40px;
  }

  .LogoH div {
    height: 41px;
    width: 20px;
  }

  .LogoS0 {
    top: 0px;
    left: 1px;
  }

  .LogoS1 {
    top: 1px;
    left: 0px;
  }

  .LogoS2 {
    left: 0px;
    top: 14px;
  }

  .LogoS3 {
    left: 1px;
    top: 15px;
  }

  .LogoS4 {
    left: 1px;
    top: 28px;
  }

  .LogoS5 {
    left: 0px;
    top: 29px;
  }

  .LogoH0 {
    left: 42px;
    top: 1px;
  }

  .LogoH1 {
    left: 43px;
    top: 0px;
  }

  .LogoH2 {
    left: 63px;
    top: 1px;
  }

  .LogoH3 {
    left: 64px;
    top: 0px;
  }
}
