.HelloPage {
  .Logo {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .Background {
    background: url(../../public/img/cloud.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;

    img {
      min-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .RalphWiggumQuote {
    color: white;
    padding: 10px;
    font-size: 4vw;
    font-weight: bold;
    max-width: 50vw;
    margin: 10vw auto;
    text-align: center;
    text-shadow: 0 0 6vw #ffffff;
  }

  .Uses {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    .ExternalLink {
      color: white;
      margin: 2px;
      display: inline-block;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
