.FoodPage {
	height: 100%;

	.food_container {
		position: relative;
		height: 100%;

		.name {
			text-align: center;
			-webkit-transform : rotate(-5deg);
			transform: rotate(-5deg);
			position: fixed;
			top: 200px;
			left: 150px;
			font-weight: bold;
			font-size: 15vw;
			font-family: Helvetica;
		}
	}
}
