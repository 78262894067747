html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica sans-serif;
  font-size: 40px;
  font-weight: 100;
}

body {
  margin: 0;
  padding: 0;
}
