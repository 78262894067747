.GrapeLadyPage {
	padding: 0;
	margin: 0;

	.YouTubeSampler {
		height: 100vh;

		#Overlay {
			background-color: rgba(1, 1, 1, 0.0);
			bottom: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
		}

		#Player {
			width: 100%;
			height: 100%;
		}
	}
}
