@import "../Core/mixins.scss";

.Insta {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    max-width: 75vw;
    font-size: 10vw;
    display: block;
    padding: 40px;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-weight: bold;
    text-decoration: none;
    color: white;
    -webkit-animation: halo 10s ease-in-out infinite alternate;
    -moz-animation: halo 10s ease-in-out infinite alternate;
    animation: halo 10s ease-in-out infinite alternate;
  }
}

@include keyframes(halo) {
  from {
    text-shadow : 0 0 1px #fff, 0 0 2px #fff, 0 0 5px #fff;
  }
  to {
    text-shadow : 0 0 100px #fff, 0 0 200px #fff, 0 0 500px #fff;
  }
}