@import "../Core/mixins.scss";

.HowToStopAWeddingPage {
  .center {
    height: 100%
	}

  .container {
    position : relative;
    height   : 503px;
    width    : 670px;
    overflow : hidden;
    cursor   : pointer;
		margin   : 20px;

    .step {
      position           : absolute;
      z-index            : 10;
      bottom             : 10px;
      left               : 0px;
      font-size          : 500px;
      text-align         : center;
      line-height        : 503px;
      font-family        : 'Helvetica Neue';
      color              : #e8174d;
      font-weight        : bold;
      height             : 503px;
      width              : 670px;
      -webkit-transition : all 0.1s;
      -moz-transition    : all 0.1s;
      transition         : all 0.1s;
		}

    .step:hover {
      color              : #ffffff;
      -webkit-animation  : neon 0.5s ease-in-out infinite alternate;
      -moz-animation     : neon 0.5s ease-in-out infinite alternate;
      animation          : neon 0.5s ease-in-out infinite alternate;
		}

    img {
      position : absolute;
      left     : 0px;
      z-index  : 0;
		}
	}
}

@include keyframes(neon) {
  from {
    text-shadow : 0 0 25px #fff, 0 0 50px  #fff, 0 0 75px  #fff, 0 0 100px  #FF1177, 0 0 125px  #FF1177, 0 0 150px  #FF1177, 0 0 175px #FF1177, 0 0 200px #FF1177;
	}
	to {
    text-shadow : 0 0 12px #fff, 0 0 25px #fff, 0 0 37px #fff, 0 0 50px #FF1177, 0 0 67px #FF1177, 0 0 75px #FF1177, 0 0 87px #FF1177, 0 0 100px #FF1177;
	}
}
