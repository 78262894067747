.LetsGoToJapanPage {
  height: 100%;
  
  .Flag {
    height: 100%;
    width: 100%;
    background-color: black;

    .Circle {
      display: block;
      width: 0px;
      height: 0px;
      border-radius: 0px;
      background-color: white;

      .TextWrap {
        height: 100%;
        width: 100%;

        .Text {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: block;
          font-size: 0px;
          font-family: Helvetica;
          font-weight: 100;
        }
      }
    }
  }
}
