@import "../Core/mixins.scss";

.VideosPage {
	background-color: black;
	min-height: 100%;

	.Links {
		padding: 10px;

		.Link {
			display: block;
      text-decoration: none;
      font-weight: bold;
      font-size: 36px;
			font-family: Helvetica;
			color: white;

			&:hover {
				color: $blue;
			}
		}
	}
}

.VideoPage {

}
