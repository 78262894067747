.ShirtsPage {
  padding: 10px;
  .Shirt {
    display: inline-block;
    margin: 50px auto;
    width: 45vw;
    img {
      width: 45vw;
    }
  }
}
