.BearStopThatPage {
	min-height: 100%;
	background-color: black;
	margin: 0;

	marquee a {
		font-size: 100vh;
		font-weight: bold;
		font-family: 'Helvetica';
		text-decoration: none;
		color: white;
	}
}
