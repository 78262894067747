.PlaylistsPage {
	background-color: black;
	padding: 20px;
	min-height: 100%;

	.Playlists {
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;

		.Playlist {
			vertical-align: top;
			float: left;
			width: 300px;
			height: 300px;
			margin: 20px;
		}
	}

	.Playlists:after {
  	content: "";
  	display: table;
  	clear: both;
	}
}
