$blue: rgb(50, 153, 246);

@mixin noselect {
  -webkit-touch-callout : none;
  -webkit-user-select   : none;
  -khtml-user-select    : none;
  -moz-user-select      : none;
  -ms-user-select       : none;
  user-select           : none;
}

@mixin rotate ($amt) {
  -webkit-transform : rotate($amt);
  -moz-transform    : rotate($amt);
  -webkit-transform : rotate($amt);
  transform         : rotate($amt);
}

@mixin keyframes ($name) {
  @-webkit-keyframes #{$name} {
    @content;
	}

  @-moz-keyframes #{$name} {
    @content;
	}

  @-ms-keyframes #{$name} {
    @content;
	}

  @keyframes #{$name} {
    @content;
	}
}

@mixin foreverAnimation ($name, $time) {
  -webkit-animation: #{$name} #{$time} infinite;
  -moz-animation: #{$name} #{$time} infinite;
  animation: #{$name} #{$time} infinite;
}

@mixin animation ($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  animation: $animation;
}
