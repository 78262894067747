@import "../Core/mixins.scss";

.AnimalsPage {
	background-color: black;
	height: 100%;

	* {
		padding: 0;
		margin: 0;
	}

	.AnimalLink {
		text-decoration: none;
		color: white;
		display: inline-block;
		padding: 4px;
		font-family: Helvetica;
		font-size: 40px;
		font-weight: 100;
	}
}

@include keyframes(spin) {
	100% {
		transform : rotate(360deg);
		-webkit-transform : rotate(360deg);
		-moz-transform : rotate(360deg);
	}
}


.AnimalPage {
	height: 100%;
	.Animal {
    @include noselect();

    position : relative;
    overflow : hidden;
    width    : 100%;
    height   : 100%;

    .Background {
			background-color: black;
			position: absolute;
			left: -250%;
			top: -250%;
			width: 500%;
			height: 500%;
			z-index: -1;
			-ms-transform-origin: 60% 60%;
			-webkit-transform-origin: 60% 60%;
			transform-origin: 60% 60%;
			background-color: black;
			background-repeat: repeat;
			background-position: 0 0;
		}

		.GroupName {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			font-size: 16vh;
			color: white;
			text-shadow: 5px 5px black;
			font-weight: bold;
			word-wrap: break-word;
			cursor: pointer;
		}
	}
}
