@keyframes FadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.HomePage {
	h1 {
		padding: 10px;
		-webkit-animation: FadeOut 100s;
						animation: FadeOut 100s;
	}
}
