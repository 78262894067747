.MoustachesPage {
  padding: 0px;
  margin: 0px;
  background-color: black;

  .container {
    position: relative;

    .background {
      cursor: crosshair;
      width: 100%;
		}

    .moustache {
      position: absolute;
      z-index: 10;
      cursor: pointer;
		}
	}
}
